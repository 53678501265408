import { Component, HostListener, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Settings } from "../../app.settings.model";
import { MatDialog } from "@angular/material/dialog";
import { AppSettings } from "src/app/app.settings";
import { ApiSevicesService } from "src/app/shared/services/api-sevices.service";
import { FileDowloadService } from "src/app/shared/services/file-dowload.service";
import { PagerModule } from "src/app/shared/constants/pages.const";
import { ConfirmationComponent } from "src/app/shared/component/confirmation/confirmation.component";
@Component({
  selector: "app-rollback",
  templateUrl: "./rollback.component.html",
  styleUrls: ["./rollback.component.scss"],
})
export class RollbackComponent implements OnInit {
  public settings: Settings;
  page: any = PagerModule;
  formData: FormData | null = null;
  constructor(
    public dialog: MatDialog,
    public appSettings: AppSettings,
    public api: ApiSevicesService,
    private toast: ToastrService,
    private fileDownload: FileDowloadService
  ) {
    this.settings = this.appSettings.settings;
  }
  ngOnInit() {
    setTimeout(() => {
      this.getList();
    }, 0);
  }

  public displayedColumns: string[] = [
    "id",
    "fileName",
    "totalRecords",
    "rollbackRecords",
    "created_at",
    "status",
    "action",
  ];
  public dataSource: any = [];

  getList() {
    this.settings.loadingSpinner = true;
    this.api.getROllback(this.page).subscribe({
      next: (res: any) => {
        this.dataSource = res.data;
        this.page = res.pager;
        this.settings.loadingSpinner = false;
      },
      error: (error) => {
        this.toast.error(error.error.message);
        this.settings.loadingSpinner = false;
      },
    });
  }

  refresh() {
    this.getList();
  }

  deleteFile(id: number, name: string) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        filename: name,
        title: "Delete",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.settings.loadingSpinner = true;
        this.api.deleteRollback(id).subscribe({
          next: (res: any) => {
            this.toast.success(res.message);
            this.getList();
          },
          error: (error) => {
            this.toast.error(error.error.message);
            this.settings.loadingSpinner = false;
          },
        });
      }
    });
  }

  downloadFile(id: number, name: string) {
    this.settings.loadingSpinner = true;
    this.api.downloadRollback(id).subscribe({
      next: (res) => {
        this.fileDownload.downloadFIle(res, name);
        this.toast.success("File Download Succefull.");
        this.settings.loadingSpinner = false;
      },
      error: (error) => {
        this.toast.error(error.error.message);
        this.settings.loadingSpinner = false;
      },
    });
  }

  pageChange(e: any) {
    this.page.limit = e.pageSize;
    this.page.page = e.pageIndex + 1;
    this.getList();
  }
}
