// export const PagerModule = {
//   limit: 10,
//   page: 1,
//   total_records: 0,
// };

export const PagerModule = {
  limit: 20,
  page: 1,
  total_records: 0,
};