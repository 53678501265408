export enum apiUrl {
  //Login Api URL
  LOGIN = "auth/login",
  FORGOT_PASSWORD = "auth/forgot-password",
  RESET_PASSWORD = "auth/reset-password",

  //Query Api URL
  CREATE_QUERY = "query/upload",
  GET_QUERY = "query/list",
  DELETE_QUERY = "query/delete/",
  DOWNLOAD_QUERY = "query/export/",

  //Register Api URL
  CREATE_REGISTER = "register/upload",
  GET_REGISTER = "register/list",
  DELETE_REGISTER = "register/delete/",
  DOWNLOAD_REGISTER = "register/export/",
  ROLLBACK_REGISTER = "rollback/convert-rollback",

  //Report Api URL
  CREATE_REPORT = "report/upload",
  GET_REPORT = "report/list",
  DELETE_REPORT = "report/delete/",
  DOWNLOAD_REPORT = "report/export/",

  // ROllback Api Url
  GET_ROLLBACK = "rollback/list",
  DELETE_ROLLBACK = "rollback/delete/",
  DOWNLOAD_ROLLBACK = "rollback/export/",
}
