import { LocalstorageServicesService } from "src/app/shared/services/localstorage-services.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { AppSettings } from "../app.settings";
import { Settings } from "../app.settings.model";
import { Router } from "@angular/router";
import { ConfirmationComponent } from "../shared/component/confirmation/confirmation.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
  providers: [],
})
export class PagesComponent implements OnInit {
  @ViewChild("sidenav") sidenav: any;
  public settings: Settings;
  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private LocalstorageServices: LocalstorageServicesService,
    private dialog: MatDialog
  ) {
    this.settings = this.appSettings.settings;
    this.settings.loadingSpinner = false;
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  public toggleSidenav() {
    this.sidenav.toggle();
  }

  logout() {
    const dialogRef = this.dialog.open(ConfirmationComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.LocalstorageServices.clearStorage();
        this.router.navigate(["/login"]);
      }
    });
  }
}
