import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "fileStatus",
})
export class FileStatusPipe implements PipeTransform {
  transform(status: number): string {
    switch (status) {
      case 1:
        return "Processing";
      case 2:
        return "Complete";
      case 3:
        return "Fail";
      case 4:
        return "RollBack";
      default:
        return "-";
    }
  }
}
