import { Injectable, Injector } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { LocalstorageServicesService } from "../services/localstorage-services.service";

@Injectable()
export class TokenInterceptorInterceptor implements HttpInterceptor {
  constructor(private localStorageService: LocalstorageServicesService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: any = this.localStorageService.getLoggerToken();

    return next.handle(
      request.clone({ setHeaders: { authorization: `Bearer ${token}` } })
    );
  }
}
