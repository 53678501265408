import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-confirmation",
  templateUrl: "./confirmation.component.html",
  styleUrls: ["./confirmation.component.scss"],
})
export class ConfirmationComponent implements OnInit {
  public filename: string;
  public title: string;

  constructor(
    private modalRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.filename = this.data?.filename;
    this.title = this.data?.title;
  }

  confirm() {
    this.modalRef.close(true);
  }
  cancel() {
    this.modalRef.close(false);
  }
}
