import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FileDowloadService {
  constructor() {}

  downloadFIle(data: any, name: string) {
    var parts = name.split(".");
    var filename = parts[0];
    const a = document.createElement("a");
    const objectUrl = URL.createObjectURL(data);
    a.href = objectUrl;
    a.download = filename + `.xlsx`;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }
}
