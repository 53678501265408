import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { PagesComponent } from "./pages/pages.component";
import { QueryComponent } from "./pages/query/query.component";
import { RegisterComponent } from "./pages/register/register.component";
import { ReportComponent } from "./pages/report/report.component";
import { RollbackComponent } from "./pages/rollback/rollback.component";
import { AuthGuardGuard } from "./shared/auth/auth-guard.guard";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  // {
  //   path: "reset-password",
  //   component: ResetPasswordComponent,
  // },
  {
    path: "reset-password/:email/:token",
    component: ResetPasswordComponent,
  },
  {
    path: "pages",
    component: PagesComponent,
    canActivate: [AuthGuardGuard],
    children: [
      {
        path: "",
        redirectTo: "query",
        pathMatch: "full",
      },
      {
        path: "query",
        component: QueryComponent,
      },
      {
        path: "register",
        component: RegisterComponent,
      },
      {
        path: "report",
        component: ReportComponent,
      },
      {
        path: "rollback",
        component: RollbackComponent,
      },
    ],
  },
  { path: "**", redirectTo: "login", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
