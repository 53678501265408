import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OverlayContainer } from "@angular/cdk/overlay";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";
import { PagesComponent } from "./pages/pages.component";
import { AppSettings } from "./app.settings";
import { VerticalMenuComponent } from "./theme/components/menu/vertical-menu/vertical-menu.component";
import { SidenavComponent } from "./theme/components/sidenav/sidenav.component";
import { QueryComponent } from "./pages/query/query.component";
import { RegisterComponent } from "./pages/register/register.component";
import { ReportComponent } from "./pages/report/report.component";
import { RollbackComponent } from "./pages/rollback/rollback.component";
import { ToastrModule } from "ngx-toastr";
import { TokenInterceptorInterceptor } from "./shared/interceptor/token-interceptor.interceptor";
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,

    PerfectScrollbarModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    SidenavComponent,
    VerticalMenuComponent,
    QueryComponent,
    RegisterComponent,
    ReportComponent,
    RollbackComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
  ],
  providers: [
    AppSettings,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorInterceptor,
      multi: true,
    },
    { provide: OverlayContainer },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
