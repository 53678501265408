import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AppSettings } from "src/app/app.settings";
import { Settings } from "src/app/app.settings.model";
import { ApiSevicesService } from "src/app/shared/services/api-sevices.service";
import { LocalstorageServicesService } from "src/app/shared/services/localstorage-services.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  public resetFrom: UntypedFormGroup;
  public settings: Settings;
  constructor(
    public appSettings: AppSettings,
    public fb: UntypedFormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private api: ApiSevicesService
  ) {
    this.settings = this.appSettings.settings;
    this.resetFrom = this.fb.group({
      password: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]],
    });
  }
  ngOnInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 0);
  }

  public submit(data: any) {
    if (this.resetFrom.valid) {
      if (
        this.resetFrom.controls["password"].value ===
        this.resetFrom.controls["confirmPassword"].value
      ) {
        this.settings.loadingSpinner = true;
        data.email = this.route.snapshot.params["email"];
        data.token = this.route.snapshot.params["token"];
        this.api.resetPassword(data).subscribe({
          next: (res: any) => {
            this.settings.loadingSpinner = false;
            this.toast.success(res.message);
            this.router.navigate(["/login"]);
          },
          error: (error) => {
            this.settings.loadingSpinner = false;
            this.toast.error(error.error.message);
          },
        });
      } else {
        this.toast.error("Passwords do not match. Please try again.");
      }
    }
  }
}
