import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AppSettings } from "src/app/app.settings";
import { Settings } from "src/app/app.settings.model";
import { ApiSevicesService } from "src/app/shared/services/api-sevices.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  public forgotForm: UntypedFormGroup;
  public settings: Settings;
  constructor(
    public appSettings: AppSettings,
    public fb: UntypedFormBuilder,
    public router: Router,
    private toast: ToastrService,
    private api: ApiSevicesService
  ) {
    this.settings = this.appSettings.settings;
    this.forgotForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }
  ngOnInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 0);
  }

  public submit(data: any) {
    if (this.forgotForm.valid) {
      this.settings.loadingSpinner = true;
      this.api.forgotPassword(data).subscribe({
        next: (res: any) => {
          this.settings.loadingSpinner = false;
          this.toast.success(res.message);
          this.router.navigate(["/login"]);
        },
        error: (error) => {
          this.settings.loadingSpinner = false;
          this.toast.error(error.error.message);
        },
      });
    }
  }
}
