import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { apiUrl } from "../enum/apiUrl.enum";

@Injectable({
  providedIn: "root",
})
export class ApiSevicesService {
  constructor(private http: HttpClient) {}

  //LOGIN API SERVICES
  login(data: any) {
    return this.http.post(`${environment.baseUrl}${apiUrl.LOGIN}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  // FORGOT PASSWORD API SERVICES
  forgotPassword(data: any) {
    return this.http.post(
      `${environment.baseUrl}${apiUrl.FORGOT_PASSWORD}`,
      data
    );
  }

  // RESER PASSWORD API SERVICES
  resetPassword(data) {
    return this.http.post(
      `${environment.baseUrl}${apiUrl.RESET_PASSWORD}`,
      data
    );
  }

  //QUERY API SERVICES
  createQuery(data: FormData) {
    return this.http.post(`${environment.baseUrl}${apiUrl.CREATE_QUERY}`, data);
  }

  getQuery(page: any) {
    return this.http.get(`${environment.baseUrl}${apiUrl.GET_QUERY}`, {
      params: page,
    });
  }

  deleteQuery(id: number) {
    return this.http.delete(
      `${environment.baseUrl}${apiUrl.DELETE_QUERY}${id}`
    );
  }

  downloadQuery(id: number) {
    return this.http.get(
      `${environment.baseUrl}${apiUrl.DOWNLOAD_QUERY}${id}`,
      { responseType: "blob" }
    );
  }

  //REGISTER API SERVICES
  createRegister(data: FormData) {
    return this.http.post(
      `${environment.baseUrl}${apiUrl.CREATE_REGISTER}`,
      data
    );
  }

  getRegister(pager: any) {
    return this.http.get(`${environment.baseUrl}${apiUrl.GET_REGISTER}`, {
      params: pager,
    });
  }

  deleteRegister(id: number) {
    return this.http.delete(
      `${environment.baseUrl}${apiUrl.DELETE_REGISTER}${id}`
    );
  }

  downloadRegister(id: number) {
    return this.http.get(
      `${environment.baseUrl}${apiUrl.DOWNLOAD_REGISTER}${id}`,
      { responseType: "blob" }
    );
  }

  rollbackRegister(data: any) {
    return this.http.post(
      `${environment.baseUrl}${apiUrl.ROLLBACK_REGISTER}`,
      data
    );
  }

  //REPORT API SERVICES
  createReport(data: FormData) {
    return this.http.post(
      `${environment.baseUrl}${apiUrl.CREATE_REPORT}`,
      data
    );
  }

  getReport(pager: any) {
    return this.http.get(`${environment.baseUrl}${apiUrl.GET_REPORT}`, {
      params: pager,
    });
  }

  deleteReport(id: number) {
    return this.http.delete(
      `${environment.baseUrl}${apiUrl.DELETE_REPORT}${id}`
    );
  }

  downloadReport(id: number) {
    return this.http.get(
      `${environment.baseUrl}${apiUrl.DOWNLOAD_REPORT}${id}`,
      { responseType: "blob" }
    );
  }

  //ROLLBACK API SERVICES
  getROllback(pager: any) {
    return this.http.get(`${environment.baseUrl}${apiUrl.GET_ROLLBACK}`, {
      params: pager,
    });
  }

  deleteRollback(id: number) {
    return this.http.delete(
      `${environment.baseUrl}${apiUrl.DELETE_ROLLBACK}${id}`
    );
  }

  downloadRollback(id: number) {
    return this.http.get(
      `${environment.baseUrl}${apiUrl.DOWNLOAD_ROLLBACK}${id}`,
      { responseType: "blob" }
    );
  }
}
